import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, InputAdornment, Stack } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import ProductAutocompleteField from '@/components/ProductAutocompleteField/ProductAutocompleteField';
import InputCalendar from '@/components/ui/InputCalendar/InputCalendar';
import NumberInput from '@/components/ui/NumberInput/NumberInput';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import { directRequestFormVariantPropType } from '@/constants/propTypes';
import useFormatMessage from '@/hooks/useFormatMessage';

import {
  ActionButton,
  InnerWrapper,
  MainWrapper,
  RentalObjectWrapper,
  Wrapper,
} from './RowVariant.style';

const RowVariant = React.forwardRef(
  (
    {
      className,
      onSubmit,
      control,
      handleHoursChangeFromRentalObject,
      handleOnChange,
      handleDateFromChange,
      handleDateToChange,
      dateFromWatched,
      showHourCounter,
      isSubmitting,
      submitButtonLabel,
    },
    ref
  ) => {
    const formatMessage = useFormatMessage();

    return (
      <Wrapper className={className} ref={ref} data-cy="form: direct request">
        <MainWrapper>
          <form noValidate onSubmit={onSubmit}>
            <InnerWrapper>
              <RentalObjectWrapper>
                <Box flex={1}>
                  <Controller
                    name="rentalObject"
                    control={control}
                    render={({
                      field: { ref, value, onChange, name, onBlur },
                      fieldState: { error },
                    }) => (
                      <ProductAutocompleteField
                        required
                        error={!!error}
                        inputRef={ref}
                        helperText={
                          error ? formatMessage('dr_form_machineError') : ' '
                        }
                        value={value?.name}
                        onChange={value => {
                          handleHoursChangeFromRentalObject(value);
                          handleOnChange({
                            onChange,
                            inputName: name,
                            value,
                          });
                        }}
                        onBlur={onBlur}
                        name={name}
                        only="rentable"
                        variant="outlined"
                        size="medium"
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="count"
                  control={control}
                  render={({
                    field: { ref, value, onChange, onBlur, name },
                    fieldState: { error },
                  }) => {
                    return (
                      <Box
                        sx={{
                          width: { xs: '100%', sm: 100, lg: 75 },
                        }}>
                        <NumberInput
                          id={name}
                          name={name}
                          value={value}
                          label={formatMessage('dr_form_quantity')}
                          inputRef={ref}
                          onChange={value => {
                            handleOnChange({
                              onChange,
                              inputName: name,
                              value,
                            });
                          }}
                          onBlur={onBlur}
                          error={!!error}
                          min={1}
                          max={999}
                        />
                      </Box>
                    );
                  }}
                />
              </RentalObjectWrapper>
              <Stack
                flexDirection={{ xs: 'column', sm: 'row' }}
                gap={{ xs: 0, sm: 1, md: 1.5 }}>
                <Stack
                  flex={1}
                  gap={{ xs: 0, sm: 1, md: 1.5 }}
                  flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Controller
                    name="dateFrom"
                    control={control}
                    render={({
                      field: { ref, value, onChange, onBlur, name },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputCalendar
                          id="directRequestDateFrom"
                          value={value}
                          label={formatMessage('dr_form_start_date')}
                          onChange={e => handleDateFromChange(e, onChange)}
                          onBlur={onBlur}
                          tabIndex={0}
                          inputRef={ref}
                          name={name}
                          error={!!error}
                          fullWidth
                          helperText={
                            error
                              ? formatMessage('dr_form_mandatoryInputsHelper', {
                                  inputName: formatMessage(
                                    'dr_form_start_date'
                                  ),
                                })
                              : ' '
                          }
                          required
                        />
                      );
                    }}
                  />
                  <Controller
                    name="dateTo"
                    control={control}
                    render={({
                      field: { onChange, onBlur, ref, value, name },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputCalendar
                          id="directRequestDateFrom"
                          label={formatMessage('dr_form_end_date')}
                          name={name}
                          value={value}
                          onChange={value => {
                            handleOnChange({
                              onChange,
                              inputName: name,
                              value,
                            });
                            handleDateToChange(value);
                          }}
                          onBlur={onBlur}
                          tabIndex={0}
                          inputRef={ref}
                          error={!!error}
                          fullWidth
                          helperText={
                            error
                              ? formatMessage('dr_form_mandatoryInputsHelper', {
                                  inputName: formatMessage('dr_form_end_date'),
                                })
                              : ' '
                          }
                          required
                          disabledTo={dateFromWatched}
                          presented={
                            dateFromWatched
                              ? new Date(
                                  new Date(dateFromWatched).setDate(
                                    new Date(dateFromWatched).getDate() + 1
                                  )
                                )
                              : new Date()
                          }
                        />
                      );
                    }}
                  />
                </Stack>
                {showHourCounter && (
                  <Controller
                    name="rentalHours"
                    control={control}
                    render={({
                      field: { ref, value, onChange, onBlur, name },
                    }) => {
                      return (
                        <Box width={{ xs: '100%', sm: 100 }}>
                          <NumberInput
                            id={name}
                            name={name}
                            value={value}
                            label={formatMessage('dr_form_hours')}
                            inputRef={ref}
                            data-cy="hour-counter"
                            onChange={value => {
                              handleOnChange({
                                onChange,
                                inputName: name,
                                value,
                              });
                            }}
                            onBlur={onBlur}
                            min={1}
                            max={24}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccessTimeIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      );
                    }}
                  />
                )}
              </Stack>
              <ActionButton
                color="primary"
                size="large"
                data-cy="button: addToBasket"
                type="submit"
                fullWidth
                tabIndex={0}
                disabled={isSubmitting}
                startIcon={<SvgIcon name="shoppingCart" sx={{ mb: '3px' }} />}>
                {submitButtonLabel ?? formatMessage('dr_form_cta')}
              </ActionButton>
            </InnerWrapper>
          </form>
        </MainWrapper>
      </Wrapper>
    );
  }
);

RowVariant.displayName = 'RowVariant';

RowVariant.propTypes = directRequestFormVariantPropType;

export default RowVariant;
